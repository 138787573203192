import React, { useContext, useEffect, useState } from 'react'
import { context } from '~/layouts'
import { PageProps } from 'gatsby'
import { Head, Button, Footer, WhatsApp } from '~/components'
import * as st from '~/assets/styl/NotFound.module.styl'

const NotFound = ({ location }: PageProps) => {
  const { setLoading } = useContext(context)
  const [ready, setReady] = useState(false)
  useEffect(() => {
    setLoading(false)
    setReady(true)
  }, [])

  return ready ? (
    <>
      <Head
        location={location}
        title={`Página não encontrada - ${process.env.GATSBY_SITE_NAME}`}
      />
      <section className={st.core}>
        <div className={st.container}>
          <div>
            <h3>Página não encontrada</h3>
            <p>
              Erro 404. A página que você tentou acessar não existe ou está fora
              do ar temporariamente.
            </p>
            <div className={st.links}>
              <Button href="/">Home</Button>
              <Button href="/empreendimentos/">Empreendimentos</Button>
              <Button href="/contato/">Fale conosco</Button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <WhatsApp />
    </>
  ) : (
    <Head location={location} />
  )
}

export default NotFound
